import { useEffect, useState } from 'react';
import imgFreeChallenge from '../../../../assets/images/newImages/Eileen_Intro.jpg';
import { Button } from '../../../ui-core';
import '../fitTestModal/styles.scss';

export const AdsModal = ({ onClose, data, t }) => {


    const onSend = () => {
        data?.callback();
        onClose && onClose();
    };

    const closeHandler = () => {
        onClose && onClose();
    };

    return (
        <>
            <div className="free-default-modal ads-modal" style={{ paddingTop: 20, paddingBottom: 10, width: 360 }}>
                {/* <img onClick={closeHandler} src={imgClose} className="default-modal-close" alt={'close'} /> */}
                <div className="start-modal-body">
                    <div className="start-left-content">
                        <p className="start-modal-title  ads-modal">New & Improved</p>
                        <video width={300} style={{ borderRadius: 10, marginTop: 40 }} autoPlay
                            loop
                            muted
                            playsInline
                        >
                            <source src="https://cdn.prod.website-files.com/66ec602c2c93191d68180b71/66ec62adf80ecdbb0abec197_before2-poster-00001.jpg" data-wf-ignore="true" />
                            <source src="https://cdn.prod.website-files.com/66ec602c2c93191d68180b71/66ec62adf80ecdbb0abec197_before2-transcode.mp4" data-wf-ignore="true" />
                            <source src="https://cdn.prod.website-files.com/66ec602c2c93191d68180b71/66ec62adf80ecdbb0abec197_before2-transcode.webm" data-wf-ignore="true" />
                        </video>
                        <p className="start-modal-content-1">
                            We've added some exciting new features, like the ability to adjust your workout difficulty in real time and advanced customization options. Ready to try these enhancements? Don’t worry, you can switch back anytime!
                        </p>
                        <div className='modal-actions'>
                            
                            <button className="free-popup-select-submit-not-now ads-modal" style={{ borderRadius: 25, fontSize: 12 }} onClick={onSend}>
                                Try New Features
                            </button>
                            <button className="free-popup-select-submit-not-now ads-modal" style={{ borderRadius: 25, background: 'white', border: '1px solid black', color: 'black', fontSize: 12 }} onClick={closeHandler}>
                                Maybe Later
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
};
