import { logError } from '../../utils/globalUtils';
import axios from 'axios';

const getDeviseID = () => {
    const a = localStorage.getItem('DeviseID');
    if (a) {
        return a;
    }
    const newDeviseId = Math.random() * 10e16 + '';
    localStorage.setItem('DeviseID', newDeviseId);
    return newDeviseId;
};
const jsonHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
    'X-TIMEZONE': -new Date().getTimezoneOffset() / 60,
    deviceId: getDeviseID(),
    platform: 'WEB',
};

const formDataHeaders = {
    accept: '*/*',
    ContentType: 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
};

const defautCustomOptions = {
    includeAuthHeader: true,
    includeCredentials: true,
};

export async function post(path, body = {}, headers = {}, options = {}, customOptions = defautCustomOptions) {
    const request = await fetchEnriched(
        'POST',
        path,
        JSON.stringify(body),
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );
    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        } else {
            const type = request.headers.get('Content-Type');

            //temporary solution, it is necessary to change the returned text
            if (request.url.includes('send-confirm-code')) {
                return request;
            }

            switch (type) {
                case 'application/json; charset=utf-8':
                    return request.json();
                case 'text/plain; charset=utf-8':
                    return request;
                default:
                    return request;
            }
        }
    } catch (err) {
        if (path.includes('error') == false) logError(err, `post-Error`, path);
        throw err;
    }
}

export async function postFormData(path, body = {}, headers = {}, options = {}, customOptions = defautCustomOptions) {
    const request = await fetchEnriched(
        'POST',
        path,
        body,
        getHeaders(formDataHeaders, headers),
        options,
        customOptions,
    );

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }
        return request.json();
    } catch (err) {
        logError(err, `post-Form-Error`, path);
        throw err;
    }
}

export async function putFormData(path, body = {}, headers = {}, options = {}, customOptions = defautCustomOptions) {
    const request = await fetchEnriched(
        'PUT',
        path,
        body,
        getHeaders(formDataHeaders, headers),
        options,
        customOptions,
    );

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        } else {
            if (typeof request === 'string') {
                return request.json();
            } else {
                return request.json();
            }
        }
    } catch (err) {
        logError(err, `put-Form-Error`, path);
        throw err;
    }
}

export async function get(path, body = {}, headers = {}, options = {}, customOptions = defautCustomOptions) {
    const request = await fetchEnriched('GET', path, null, getHeaders(jsonHeaders, headers), options, customOptions);

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }

        return request.json();
    } catch (err) {
        logError(err, `get-api-Error`, path);

        throw err;
    }
}

export async function patch(path, body = {}, headers = {}, options = {}, customOptions = defautCustomOptions) {
    const request = await fetchEnriched(
        'PATCH',
        path,
        JSON.stringify(body),
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }

        try {
            return await request.json();
        } catch (e) {
            logError(e, `patch-api-Error`, path);

            return request;
        }
    } catch (err) {
        logError(err, `patch-api-Error`, path);

        throw err;
    }
}

export async function put(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
    isConvertToJSON = true,
) {
    const request = await fetchEnriched(
        'PUT',
        path,
        isConvertToJSON ? JSON.stringify(body) : body,
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }
        return request.json();
    } catch (err) {
        logError(err, `put-api-Error`, path);

        throw err;
    }
}

export async function remove(path, body = {}, headers = {}, options = {}, customOptions = defautCustomOptions) {
    const request = await fetchEnriched(
        'DELETE',
        path,
        JSON.stringify(body),
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );
    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }
        return true;
    } catch (err) {
        logError(err, `remove-api-Error`, path);
        throw err;
    }
}

export async function removeWithResponse(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
) {
    const request = await fetchEnriched('DELETE', path, null, getHeaders(jsonHeaders, headers), options, customOptions);
    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }
        return request.json();
    } catch (err) {
        throw err;
    }
}

function fetchEnriched(method, path, body, headers, options) {
    const endpointUrl = getApiUrl(path);
    addAuthHeader(headers);
    return fetch(endpointUrl, {
        method: method,
        credentials: 'include',
        headers,
        body: body,
        ...options,
    });
}

export function getApiUrl(path) {
    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    return apiBaseURL + path;
}

function getHeaders(defaultHeaders, headers) {
    return {
        ...defaultHeaders,
        ...headers,
    };
}
function addAuthHeader(headers) {
    const data = localStorage.getItem('auth');
    if (data !== null) {
        try {
            const { accessToken } = typeof data === 'string' ? JSON.parse(data) : data;
            if (accessToken) {
                headers['Authorization'] = 'Bearer ' + accessToken;
            }
        } catch (error) {
            console.error('Error parsing auth token:', error);
        }
    }
}

export async function mockResponse(response) {
    return response;
}

export const markWelcomeTrialAsSeen = async () => {
    try {
        const response = await post('/notification/welcome-trial', {
            action: 'mark'
            // MongoDB will automatically add createdAt timestamp
        });
        console.log('Mark Welcome Trial Response:', response);
        return response;
    } catch (err) {
        console.error('Failed to mark welcome trial as seen:', err);
        throw err;
    }
};

export const hasSeenWelcomeTrial = async () => {
    try {
        const response = await post('/notification/welcome-trial', { 
            action: 'check',
            timestamp: new Date().toISOString() // Add current timestamp to request
        });
        console.log('Welcome Trial API Full Response:', {
            response,
            type: typeof response,
            keys: Object.keys(response),
            stringified: JSON.stringify(response)
        });
        
        // If already seen, use current timestamp as seen date
        if (response.alreadySeen) {
            const now = new Date();
            return {
                ...response,
                seenDate: now.toISOString()
            };
        }
        return response;
    } catch (error) {
        console.error('Failed to check welcome trial notification:', error);
        return false;
    }
};
