import { useEffect, useState } from 'react';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import imgFreeChallenge from '../../../../assets/images/newImages/Eileen_Intro.jpg';
import { Button } from '../../../ui-core';
import './styles.scss';
import { useDispatch } from 'react-redux';

export const TrialModal = ({ onClose, data, t }) => {
    const dispatch = useDispatch();
    const [isTrialBoxVisible, setTrialBoxVisible] = useState(true);

    const closeHandler = () => {
        data?.close();
        if (onClose) onClose();
    };

    const handleButtonClick = () => {
        setTrialBoxVisible(false);
        document.getElementById('reminder').style.display = 'flex';
    };

    return (
        <div className="body-6">
            {isTrialBoxVisible && (
                <div id="trial_box" className="trial_challenge_ctn">
                    <div className="trial_center_modal">
                        <div className="large_heading_wht">
                            Get Rewarded <span className="text-span-8">for</span>
                        </div>
                        <div className="large_heading_wht">Working Out</div>
                        <div className="txt_ctn_trial">
                            <div className="normal_txt_wht">Getting started is often the hardest part.</div>
                            <div className="normal_txt_wht btm">
                                So we've created a free challenge to give you a little extra{' '}
                                <span className="text-span-9">motivation</span>. Start working now and get a reduced
                                subscription after the trial.
                            </div>
                        </div>
                        <div className="div-block-85">
                            <div className="div-block-86">
                                <div className="normal_txt_wht _2">Workout within 24 hours.</div>
                            </div>
                            <div className="div-block-87"></div>
                            <div className="div-block-88"></div>
                            <div className="w-layout-vflex price_txt_ctn">
                                <div className="price_txt">
                                    $10<span className="text-span-10">off</span>
                                </div>
                                <div className="text-block-67">Monthly</div>
                            </div>
                        </div>
                        <div className="div-block-85">
                            <div className="div-block-86">
                                <div className="normal_txt_wht _2">Workout 2x more in the first 7 days</div>
                            </div>
                            <div className="div-block-87"></div>
                            <div className="div-block-88"></div>
                            <div className="w-layout-vflex price_txt_ctn">
                                <div className="price_txt">
                                    $5<span className="text-span-10">off</span>
                                </div>
                                <div className="text-block-67">Monthly</div>
                            </div>
                        </div>
                        <div>
                            <div className="text-block-68">Complete everything and get a membership for </div>
                        </div>
                        <div className="div-block-89">
                            <div className="text-block-70">25</div>
                            <div className="text-block-69">
                                <span className="text-span-11">$10</span> / month
                            </div>
                        </div>
                        <div className="modal-actions">
                            <Button className="continue_btn w-button" onClick={handleButtonClick}>
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <div id="reminder" className="trial_challenge_ctn _2" style={{ display: 'none' }}>
                <div className="trial_center_modal">
                    <div className="txt_ctn_trial"></div>
                    <div>
                        <div className="text-block-68">Reminder, to get the initial $10 off, the first </div>
                        <div className="text-block-68">workout needs to be complete within the</div>
                        <div className="text-block-68">next 24 hours.</div>
                    </div>
                    <div className="modal-actions">
                        <Button className="continue_btn w-button" onClick={closeHandler}>
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
